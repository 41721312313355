<template>
  <section v-if="errored">
    <p>Sorry er is iets mis gegaan met het laden van deze pagina...</p>
  </section>
  <section v-else>
    <b-row>
      <b-col class="px-sm-0">
        <div v-if="postLoading">Loading...</div>
        <div v-else>
          <b-card
            role="main"
            no-body
            tag="article"
            class="mb-3 border-0 wp-content"
          >
            <b-card-body>
              <back-link :catId="catId" />
              <h1 v-html="post.title.rendered" class="pt-3 text-center"></h1>
              <p
                v-if="post.status != 'publish'"
                class="text-center alert alert-primary"
                role="alert"
              >
                -- Status: {{ post.status }} --
              </p>
              <b-card-sub-title class="mb-3 text-center" sub-title-tag="p">
                {{ post.date_gmt | moment('timezone', timezone, 'LLLL') }}
              </b-card-sub-title>
              <b-card-text v-html="post.content.rendered"></b-card-text>
              <back-link :catId="catId" />
            </b-card-body>
          </b-card>
        </div>
      </b-col>
      <b-col md="4" class="sidebar">
        <aside>
          <b-row align-h="between" class="my-3">
            <b-col cols="auto">
              <h1>{{ $t('message.blog.comments') }}</h1>
            </b-col>
            <b-col cols="auto">
              <like-button :post="post" @updateLikes="updateLikes" />
            </b-col>
          </b-row>
          <div v-if="postLoading">Loading...</div>
          <div
            v-else
            v-for="(comment, index) in comments"
            :key="index"
            class="mb-3 p-3 bg-light rounded-lg"
          >
            <b-row align-v="center">
              <b-col cols="auto" class="type-blog-header2 pr-0">{{
                comment.author_name
              }}</b-col>
              <b-col cols="auto" class="type-blog-subscript mr-auto">
                {{ comment.date_gmt | moment('from') }}
              </b-col>
            </b-row>
            <b-row>
              <b-col v-html="comment.content.rendered"></b-col>
            </b-row>
          </div>
        </aside>
        <aside class="mt-3">
          <h1 class="mb-3">{{ $t('message.blog.commentReply') }}</h1>
          <b-form @submit="onSubmitComment" v-if="showform">
            <b-form-input
              id="input-1"
              v-model="form.name"
              required
              v-bind:placeholder="$t('message.blog.commentName')"
              class="rounded mb-1"
            ></b-form-input>
            <b-form-input
              id="input-2"
              v-model="form.email"
              type="email"
              required
              v-bind:placeholder="$t('message.blog.commentEmail')"
              class="rounded mb-1"
            ></b-form-input>
            <div class="mb-3">
              <b-form-textarea
                id="textarea"
                v-model="form.text"
                required
                v-bind:placeholder="$t('message.blog.commentText')"
                rows="3"
                class="rounded-lg"
                :class="{ 'is-invalid': commentsPostErroredMessage }"
                @focus="commentsPostErroredMessage = undefined"
              ></b-form-textarea>
              <div class="invalid-feedback">
                {{ commentsPostErroredMessage }}
              </div>
            </div>
            <b-button
              type="submit"
              variant="secondary"
              class="mb-3 sidebar-button"
            >
              {{ $t('message.blog.commentSubmit') }}
            </b-button>
          </b-form>
        </aside>
      </b-col>
    </b-row>
  </section>
</template>

<script>
import BackLink from '@/components/BackLink.vue'
import LikeButton from '@/components/LikeButton.vue'
import { WpService, ApiError } from '../services/wp.service'
import { mapState } from 'vuex'
import moment from 'moment-timezone'
import {
  BRow,
  BCol,
  BButton,
  BCard,
  BCardBody,
  BCardText,
  BCardSubTitle,
  BForm,
  BFormInput,
  BFormTextarea,
} from 'bootstrap-vue'

export default {
  name: 'BlogItem',
  components: {
    BackLink,
    LikeButton,
    'b-row': BRow,
    'b-col': BCol,
    'b-button': BButton,
    'b-card': BCard,
    'b-card-body': BCardBody,
    'b-card-text': BCardText,
    'b-card-sub-title': BCardSubTitle,
    'b-form': BForm,
    'b-form-input': BFormInput,
    'b-form-textarea': BFormTextarea,
  },
  props: {
    postId: String,
    catId: String,
  },
  data() {
    return {
      post: null,
      postLoading: true,
      comments: null,
      commentsLoading: true,
      commentsPostErroredMessage: undefined,
      errored: false,
      form: {
        name: '',
        email: '',
        text: '',
      },
      showform: true,
    }
  },
  computed: {
    ...mapState(['lang']),
    timezone: function () {
      return moment.tz.guess()
    },
  },
  watch: {
    lang: function () {
      this.getPost()
    },
  },
  methods: {
    getPost() {
      if (!this.postId) return
      this.postLoading = true
      WpService.postBySlug(this.lang, this.postId)
        .then((response) => {
          this.post = response
          this.getComments()
        })
        .catch(() => {
          this.errored = true
        })
        .finally(() => {
          this.postLoading = false
        })
    },
    getComments() {
      if (!this.post) return
      this.commentsLoading = true
      WpService.comments(this.lang, this.post.id)
        .then((response) => {
          this.comments = response
        })
        .catch(() => {
          this.errored = true
        })
        .finally(() => {
          this.commentsLoading = false
        })
    },
    onSubmitComment(evt) {
      evt.preventDefault()
      this.$ga.event('blog', 'submit comment', this.post.slug)
      WpService.postComment(this.form, this.post.id)
        .then((response) => {
          if (response.status != 'approved') {
            response.content.rendered = this.$t('message.blog.commentHoldText')
          }
          this.comments.unshift(response)
          this.form.name = ''
          this.form.email = ''
          this.form.text = ''
          this.commentsPostErroredMessage = undefined
        })
        .catch((error) => {
          if (error instanceof ApiError) {
            if (error.errorCode == 409) {
              this.commentsPostErroredMessage = this.$t(
                'message.blog.commentDuplicate'
              )
            } else {
              this.commentsPostErroredMessage = error.message
            }
          } else {
            this.commentsPostErroredMessage = this.commentsPostErroredMessage = this.$t(
              'message.blog.commentError'
            )
          }
        })
    },
    updateLikes(likes) {
      this.post.likes = likes
    },
  },
  mounted() {
    this.getPost()
  },
}
</script>

<style scoped lang="scss">
.sidebar {
  background-color: $blog-item-sidebar-background-color;
}

.sidebar-button {
  color: $sidebar-button-color;
  font-weight: 500;
}
</style>
