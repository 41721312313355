<template>
  <b-button
    v-if="post"
    variant="secondary"
    class="d-block mb-3"
    @click="onLike"
    :disabled="this.post.liked"
    :class="{ 'animate-tada': this.post.liked }"
    size="sm"
  >
    <font-awesome-icon :icon="['fas', 'heart']" class="like__icon" />
    <span class="like__number">{{ post.likes }}</span>
  </b-button>
</template>

<script>
import { WpService } from '../services/wp.service'
import { BButton } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'

import { faArrowLeft, faHeart } from '@fortawesome/free-solid-svg-icons'
library.add(faArrowLeft, faHeart)

export default {
  name: 'LikeButton',
  components: {
    'b-button': BButton,
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    post: undefined,
  },
  methods: {
    onLike() {
      this.$ga.event('blog', 'like', this.post.id)
      this.post.liked = !this.post.liked
      WpService.postLike(this.post.id).then((response) => {
        this.$emit('updateLikes', response)
      })
    },
  },
}
</script>

<style scoped lang="scss">
.like__icon {
  font-size: 1.2rem;
  margin-right: 0.3rem;
  color: $light;
}

.like__number {
  font-size: 0.8rem;
  color: $light;
}

@keyframes tada {
  from {
    transform: scale3d(1, 1, 1);
  }

  10%,
  20% {
    transform: scale3d(0.9, 0.9, 0.9) rotate3d(0, 0, 1, -3deg);
  }

  30%,
  50%,
  70%,
  90% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, 3deg);
  }

  40%,
  60%,
  80% {
    transform: scale3d(1.1, 1.1, 1.1) rotate3d(0, 0, 1, -3deg);
  }

  to {
    transform: scale3d(1, 1, 1);
  }
}

.animate-tada {
  animation-duration: 1s;
  animation-fill-mode: both;
  animation-name: tada;
}
</style>
