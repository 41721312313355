<template>
  <b-link
    :to="{ name: 'blog', params: { catId: catId } }"
    class="type-blog-link"
  >
    <font-awesome-icon :icon="['fas', 'arrow-left']" />
    {{ $t('message.blog.linkBack') }}
  </b-link>
</template>

<script>
import { BLink } from 'bootstrap-vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default {
  name: 'BackLink',
  components: {
    'b-link': BLink,
    'font-awesome-icon': FontAwesomeIcon,
  },
  props: {
    catId: String,
  },
}
</script>

<style scoped lang="scss"></style>
